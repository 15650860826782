const SPONSORS = [
  {
    brand: "",
    src: "/images/sponsors/pace.png",
  },
  {
    brand: "",
    src: "/images/sponsors/carmel.png",
  },
  {
    brand: "",
    src: "/images/sponsors/mukti.png",
  },
  {
    brand: "",
    src: "/images/sponsors/church-underground.png",
  },
  {
    brand: "",
    src: "/images/sponsors/alpha.png",
  },
];

export default SPONSORS;
